<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">APPROVED REQUEST CASKET</h4>
        </v-toolbar-title>
      </v-toolbar>

      <v-row>
        <v-col cols="12" md="12"></v-col>
        <v-col cols="12" md="5">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">NOT YET APPROVED</h4>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-center text-uppercase">
                    Name
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="item in request_casket_items"
                  :key="item.id"

                  @click="activerow(item)"
                  :class="{'info black--text': item.id===selectedDepositId}"
                >
                  <td class="text-center">
                    {{ item.name }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="7">
          <v-card-text v-if="this.report_casket_items.length>0">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">LIST OF REQUESTED CASKET</h4>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="info"
                @click="approved_requested"
                v-if="!saving"
              >
                Approve
              </v-btn>
              <v-progress-circular
                :size=30
                :width="5"
                color="info"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-toolbar>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-uppercase">
                    ID
                  </th>
                  <th class="text-center text-uppercase">
                    Casket type
                  </th>
                  <th class="text-center text-uppercase">
                    Quantity
                  </th>
                  <th class="text-center text-uppercase">
                    Amount
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="item in report_casket_items"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td class="text-center">
                    {{ item.casket_type }}
                  </td>
                  <td class="text-center">
                    {{ item.quantity }}
                  </td>
                  <td class="text-center">
                    {{ item.amount }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiDelete} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      request_casket_items: [],
      report_casket_items: [],
      branch_items: [],

      selectedDepositId: 0,
      saving: false,
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiDelete,
        },
      }
    },
    created() {
      this.request_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['get_branches_active']),
      ...mapActions('request_casket', ['get_request_casket_not_yet_approved','approved_request_casket']),
      reset() {
        Object.assign(this.$data, initialState())
      },
      request_data() {
        this.selectedDepositId = 0
        this.saving = false
        this.report_casket_items = []
        this.request_casket_items = []
        const datum = new FormData()
        datum.append('branch_id', this.branch_id);
        this.get_request_casket_not_yet_approved(datum)
          .then(response => {
            this.request_casket_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      activerow(value) {
        this.data = value
        this.selectedDepositId = value.id;
        this.report_casket_items = value.report_casket
      },
      approved_requested() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('id', this.selectedDepositId);
        this.approved_request_casket(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.request_data()
          })
          .catch(error => {
            this.alert_req = true
            this.alert_message_req = error
            this.saving_req = false
          })
      },
    }
  }
</script>
